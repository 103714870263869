<template>
  <fieldsTds
    v-if="systemId == 'SSH'"
    :fieldAttributes="{ ...fieldAttributes, type: 'checkbox' }"
    :field="field"
    :templateContent="result"
    v-on="$listeners"
     :value="value"
  ></fieldsTds>
</template>
<script>
import fieldsTds from "@/commonComponents/fieldsTds.vue";
export default {
  components: { fieldsTds },
  props: ["result", "fieldAttributes", "field","value"],
  computed: {
    systemId() {
      let client = this.result.valueLine.n_clientId;
      //console.log(this.result.fields.n_clientId.associatedOption);
      return client;

      //  $localClient.on("change", function () {
      // let systemId = $(this).find("option:selected").attr("systemId");
      // $fieldsObject.find("#n_saveOnActiveProbeRow").hide();
      // if (systemId != "" && systemId > 1 && typeof systemId != "undefined") {
      //   $fieldsObject.find("#n_saveOnActiveProbeRow").show();
      // }
      // resizeForm($fieldsObject);
    },
  },
};
</script>